import React from 'react';

import { container, first, second } from './data-heading.module.scss';

export interface IDataHeadingProps {
    firstColumn?: React.ReactNode;
    secondColumn?: React.ReactNode;
    className?: string;
}

export default function DataHeading({ firstColumn, secondColumn, className }: IDataHeadingProps) {
    return (
        <div className={[container, className].join(' ')}>
            <div className={first}>{firstColumn}</div>
            <div className={second}>{secondColumn}</div>
        </div>
    );
}
