import React from 'react';
import { Link } from 'gatsby';

import { container, icon } from './go-back-link.module.scss';
import ArrowIcon from '../../assets/images/svg/arrow-left.svg';

interface IGoBackLinkProps {
    className?: string;
    to: string;
    children?: React.ReactNode;
}

const GoBackLink: React.FC<IGoBackLinkProps> = ({ className = '', children, to }) => {
    return (
        <Link to={to} className={`${container} ${className}`}>
            <ArrowIcon className={icon} />
            {children}
        </Link>
    );
};

export default GoBackLink;
